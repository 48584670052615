<template>
  <div class="user-page">
    <div v-if="$system.user.isAuthenticated" class="container">
      <!--Page title-->
      <Title :title="title" />
      <!--Submissions table-->
      <div v-if="!loading" class="row">
        <div class="col-12 p-0">
          <div class="profile">
            <div v-if="$system.user.FirstName" class="profile-field">
              <b>First Name:</b>
              {{ $system.user.FirstName }}
            </div>
            <div v-if="$system.user.LastName" class="profile-field mt-3">
              <b>Last Name:</b>
              {{ $system.user.LastName }}
            </div>
            <div v-if="$system.user.Email" class="profile-field mt-3">
              <b>Email:</b>
              <a :href="'mailto:' + $system.user.Email">
                {{ $system.user.Email }}
              </a>
            </div>
            <div class="profile-field mt-3">
              <b>Change Password:</b>
              <b-form class="mt-3" @submit.prevent="changePassword">
                <b-spinner v-if="spinner" variant="success"></b-spinner>
                <label class="mb-3">
                  Old Password:
                  <b-input
                    type="password"
                    required
                    v-model="user.Password"
                    :state="validationPassword"
                    :disabled="activeInput"
                  />
                </label>

                <label class="mb-3">
                  New Password:
                  <b-input
                    type="password"
                    required
                    v-model="user.NewPassword"
                    :state="validationNewPassword"
                    :disabled="activeInput"
                  />
                </label>
                <button
                  class="btn btn-main"
                  type="submit"
                  :disabled="validForm"
                >
                  Change
                </button>
              </b-form>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="row flex-center mt-5">
        <b-spinner v-if="loading" variant="dark"></b-spinner>
      </div>
    </div>
    <div v-else class="container">
      <div class="row flex-center mt-5">
        <b-spinner variant="dark"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import './UserPage.scss'
import UserService from '@/services/user/user.service'
import Title from '@/components/main-layout/title/Title.vue'
export default {
  name: 'UserPage',
  components: {
    Title,
  },

  metaInfo: {
    title: 'Profile',
    titleTemplate: '%s | Merchant Portal | Nexi ',
  },
  data() {
    return {
      title: 'My Profile',
      loading: false,
      spinner: false,
      activeInput: false,
      user: {
        Id: this.$system.user.Id,
        Email: this.$system.user.Email,
        Password: '',
        NewPassword: '',
      },
    }
  },
  computed: {
    auth() {
      return this.$system.user.isAuthenticated
    },
    validForm() {
      if (
        this.user.Password &&
        this.user.NewPassword &&
        this.user.NewPassword !== this.$system.user.Password
      ) {
        return false
      } else return 'disabled'
    },
    validationPassword() {
      if (this.user.Password == this.$system.user.Password) {
        return true
      } else return null
    },
    validationNewPassword() {
      if (this.user.NewPassword == this.user.Password && this.user.Password) {
        return false
      } else if (
        this.user.NewPassword &&
        this.user.NewPassword !== this.user.Password
      ) {
        return true
      } else return null
    },
  },
  created() {
    if (!this.$system.user.isAuthenticated) {
      this.$system.firstLoading()
    }
  },
  methods: {
    async changePassword() {
      try {
        this.spinner = true
        this.activeInput = true

        this.user.Id = this.$system.user.Id
        this.user.Email = this.$system.user.Email
        const response = await UserService.changePassword(this.user)
        if (response && response.status == 'success') {
          this.$system.user.Password = this.user.NewPassword
          if (this.$system.user.Type !== 'viewer') {
            localStorage.Password = this.$system.cipher(
              process.env.NODE_ENV,
              this.user.NewPassword,
            )
          }
          this.user.Password = ''
          this.user.NewPassword = ''
          this.spinner = false
          this.activeInput = false
          this.$system.makeToast(
            'success',
            'User',
            'Password change successfull!',
          )
        } else {
          this.spinner = false
          this.activeInput = false
          this.$system.makeToast('error', 'User', 'Error to change Password!')
        }
      } catch (err) {
        this.$system.makeToast('error', 'User', 'Error to change Password!')
        this.spinner = false
        this.activeInput = false
      }
    },
  },
}
</script>
